<template>
  <div class="fill-height d-flex desk-settings">
    <zone-map class="flex-grow-1" key="map"/>
    <circles-popup v-if="circle"/>
    <template v-if="!hideSidebar">
      <v-expand-x-transition>
        <v-sheet
            class="sidebar px-4 pt-0"
            elevation="2"
            v-if="selected.length === 1 && !selectedNeighbourhood"
            width="400px">
          <single-desk :bookable="selectedBookable"/>
        </v-sheet>

        <v-sheet
            v-else-if="selected.length > 1 && !selectedNeighbourhood"
            class="sidebar px-4 pb-14 pt-0"
            elevation="2"
            width="400px">
          <multi-desk/>
        </v-sheet>

        <v-sheet
            class="sidebar px-4 pb-14 pt-0"
            elevation="2"
            v-else
            width="400px">
          <neighbourhoods-list/>
        </v-sheet>
      </v-expand-x-transition>

      <div style="width: 400px; position: fixed; bottom: 0; right: 0">
        <changes/>
      </div>

      <v-dialog
          v-if="confirmChangeDialog"
          v-model="confirmChangeDialog"
          width="500">
        <confirm-neighbourhood-change-dialog
            :desk="storedDesk"
            :old-neighbourhood="storedDeskNeighbourhood"
            :neighbourhood="selectedNeighbourhood"
            @dont-show="dontShow = $event"
            @close-dialog="confirmChangeDialog = false"/>
      </v-dialog>

      <v-dialog
          width="500"
          v-model="createDialog">
        <create-neighbourhood-dialog @close-dialog="createDialog = false"/>
      </v-dialog>

      <v-dialog width="500" v-model="navigateDialog">
        <v-card>
          <v-card-title class="text-h5 warning lighten-2">
            <v-icon left>mdi-alert-circle</v-icon>
            Unsaved Changes
          </v-card-title>
          <v-card-text class="mt-4">
            You currently have <b>{{ totalChanges }}</b> unsaved {{ totalChanges > 1 ? 'changes' : 'change' }}.
            Are you sure you want to leave this page?
            <span class="error--text">Leaving this page will discard all current changes</span>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="error"
                text
                @click="closeDialog">
              No
            </v-btn>
            <v-btn
                color="success"
                @click="navigateAway">
              Leave
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import ZoneMap from '@/views/desk-booking/settings/map/ZoneMap';
import {mapGetters, mapMutations, mapState} from 'vuex';
import SingleDesk from '@/views/desk-booking/settings/sidebar/SingleDesk';
import MultiDesk from '@/views/desk-booking/settings/sidebar/MultiDesk';
import NeighbourhoodsList from '@/views/desk-booking/settings/sidebar/NeighbourhoodsList';
import CreateNeighbourhoodDialog from '@/views/desk-booking/settings/sidebar/dialogs/CreateNeighbourhoodDialog';
import ConfirmNeighbourhoodChangeDialog
  from '@/views/desk-booking/settings/sidebar/dialogs/ConfirmNeighbourhoodChangeDialog';
import CirclesPopup from '@/views/desk-booking/settings/map/CirclesPopup';
import Changes from '@/views/desk-booking/settings/sidebar/Changes';

export default {
  name: 'DeskSettings',
  components: {
    Changes,
    ConfirmNeighbourhoodChangeDialog,
    NeighbourhoodsList,
    MultiDesk,
    ZoneMap,
    SingleDesk,
    CreateNeighbourhoodDialog,
    CirclesPopup
  },
  beforeRouteLeave(to, from, next) {
    if (this.to) {
      next();
    } else {
      if (this.totalChanges > 0) {
        this.to = to;
        this.navigateDialog = true;
      } else {
        next();
      }
    }
  },
  data() {
    return {
      createDialog: false,
      storedDesk: null,
      existingDesk: null,
      confirmChangeDialog: false,
      navigateDialog: false,
      to: null,
      /** * @type {firebase.firestore.DocumentReference[]} */
      oldSelected: [],
      dontShow: false
    };
  },
  computed: {
    ...mapGetters('auth', ['ns', 'canEditNeighbourhoods', 'canEditDeskSettings', 'isPeopleReception']),
    ...mapGetters('views/deskBooking/settings/circles', ['circle']),
    ...mapState('views/deskBooking/settings/desks', ['desks']),
    ...mapGetters('views/deskBooking/settings/desks', ['deskById', 'allEdits']),
    ...mapGetters('selection', ['lastSelected']),
    ...mapState('selection', ['selected']),
    ...mapGetters('selection', ['anySelected', 'maxSelected']),
    ...mapGetters('views/deskBooking/settings/neighbourhoods', ['sortedNeighbourhoods', 'selectedNeighbourhood']),
    ...mapState('bookables', ['byRef']),
    selectedBookable() {
      const idx = this.lastSelected.id;
      return Object.values(this.byRef).find(b => b.id === idx);
    },
    storedDeskNeighbourhood() {
      if (this.storedDesk && this.storedDesk.neighbourhood) {
        return this.sortedNeighbourhoods.find(n => n.ref.isEqual(this.storedDesk.neighbourhood.ref));
      }
      return null;
    },
    totalChanges() {
      let changes = this.allEdits.length;
      if (this.sortedNeighbourhoods) {
        for (const neighbourhood of this.sortedNeighbourhoods) {
          if (neighbourhood.edited) changes++;
          if (neighbourhood.deleted) changes++;
        }
      }
      return changes;
    },
    hideSidebar() {
      // This functionality has been added at the request of inf to hide the sidebar
      // when the user has no access to edit neighbourhoods or desk settings
      // https://vanti.youtrack.cloud/issue/INF-320/Show-basic-floorplan-page-in-workplace-to-all-users
      if (this.ns === 'inf') {
        if (this.canEditNeighbourhoods || this.canEditDeskSettings || this.isPeopleReception) {
          return false;
        }
        return true;
      }
      return false;
    }
  },
  watch: {
    'selected': {
      handler(n) {
        const newRefs = n.map(selected => selected.ref);
        const oldRefs = this.oldSelected;
        this.selectedNeighbourhoodDeskChanges(oldRefs, newRefs);
        this.oldSelected = newRefs;
      }
    }
  },
  methods: {
    ...mapMutations('selection', ['clearAll', 'toggleMaxSelected']),
    ...mapMutations('views/deskBooking/settings/neighbourhoods', ['clearNeighbourhood']),

    /**
     * Handle desk additions/removals for the selected neighbourhood
     *
     * @param {firebase.firestore.DocumentReference[]} oldRefs
     * @param {firebase.firestore.DocumentReference[]} newRefs
     */
    selectedNeighbourhoodDeskChanges(oldRefs, newRefs) {
      if (!this.selectedNeighbourhood) {
        return; // nothing to do if no neighbourhood selected, desk selection is handled elsewhere
      }
      const removed = oldRefs.filter(id => !newRefs.includes(id));
      const added = newRefs.filter(id => !oldRefs.includes(id));

      for (const deskRef of added) {
        const editableDesk = this.deskById(deskRef.id);
        if (editableDesk.isInNeighbourhood(this.selectedNeighbourhood)) {
          // If the added deskRef is in the currently bound neighbourhood
          this.removeDesk(deskRef);
        } else {
          // If the deskRef is not in the bound neighbourhood
          this.addDesk(deskRef);
        }
      }
      for (const deskRef of removed) {
        const editableDesk = this.deskById(deskRef.id);
        editableDesk && editableDesk.resetNeighbourhood();
      }
    },
    async addDesk(desk) {
      const ref = desk.ref ? desk.ref : desk;
      const editableDesk = this.deskById(ref.id);

      if (!this.dontShow && editableDesk.hasNeighbourhood) {
        this.storedDesk = editableDesk;
        this.confirmChangeDialog = true;
      } else {
        editableDesk.neighbourhood = this.selectedNeighbourhood;
      }
    },
    async removeDesk(desk) {
      const ref = desk.ref ? desk.ref : desk;
      const editableDesk = this.deskById(ref.id);
      editableDesk.neighbourhood = null;
      this.$logger.debug('removeDesk', desk, editableDesk.hasNeighbourhood, editableDesk.neighbourhood);
    },
    closeDialog() {
      this.navigateDialog = false;
      this.to = null;
    },
    async navigateAway() {
      for (const neighbourhood of this.sortedNeighbourhoods) {
        if (neighbourhood.edited || neighbourhood.deleted) {
          neighbourhood.reset();
        }
      }
      for (const desk of this.desks) {
        if (desk.edited) {
          desk.reset();
        }
      }
      this.clearNeighbourhood();
      await this.$router.push({name: this.to.name});
    }
  }
};
</script>

<style scoped>
.sidebar {
  height: calc(100vh - 68px);
  overflow-y: auto;
}
</style>
