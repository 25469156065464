import {auth} from '@/firebase';
import {Logger} from '@vanti/vue-logger';
import {arrayContainsAny} from '@/util/array';
import {claimsMatchUser} from '@/util/auth-claims';
import {isEqual} from 'lodash';

const log = Logger.get('auth');

const rolesWithAppAccess = [
  'ns.admin',
  'desk-booking.admin',
  'people.admin',
  'people.reception',
  'room-booking.admin',
  'desk-booking.data',
  'desk-booking.settings',
  'desk-booking.neighbourhoods'
];

export default {
  namespaced: true,
  state: {
    authUser: null,
    /**
     * Records any errors that happened during the last attempt to determine the auth state
     *
     * @type {null|Error}
     */
    authError: null,
    profile: {
      displayName: '',
      email: ''
    },

    // setTimeout handle used to track refreshing of the auth token if the user doc and auth claims don't match.
    refreshAuthTokenHandle: 0
  },
  getters: {
    authUser(state) {
      return state.authUser || {};
    },
    hasAuthUser(state) {
      return Boolean(state.authUser);
    },
    authUserClaims(state) {
      return (state.authUser && state.authUser.token.claims) || {};
    },
    displayName(state) {
      return state.profile.displayName;
    },
    hasAppAccess(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, rolesWithAppAccess);
    },
    hasAccessTo(state, getters) {
      const roles = getters.userRoles;

      const nsAdmin = getters.isAdmin;
      const peopleAdmin = getters.isPeopleAdmin;
      const peopleReception = arrayContainsAny(roles, ['ns.admin', 'people.admin', 'people.reception']);
      const deskBookingAdmin = getters.isDeskBookingAdmin;
      const canEditNeighbourhoods = getters.canEditNeighbourhoods;
      const canEditDeskSettings = getters.canEditDeskSettings;
      const canExportDeskBookingData = getters.canExportDeskBookingData;
      const roomBookingAdmin = arrayContainsAny(roles, ['ns.admin', 'room-booking.admin']);

      return {
        'admin': {
          'audit': nsAdmin,
          'integrations': nsAdmin,
          'siteConfig': nsAdmin
        },
        'people': {
          'access-control-restricted': peopleAdmin,
          'directory': peopleReception,
          'cards': peopleReception,
          'visitors': peopleReception,
          'users': peopleAdmin
        },
        'deskBooking': {
          'bookingsExport': canExportDeskBookingData,
          'siteSettings': deskBookingAdmin,
          'deskSettings': canEditDeskSettings,
          'neighbourhoodSettings': canEditNeighbourhoods
        },
        'roomBooking': roomBookingAdmin,
        'dashboard': {
          'liveStatus': {
            'desks': canExportDeskBookingData,
            'rooms': roomBookingAdmin
          },
          'stats': deskBookingAdmin || roomBookingAdmin
        }
      };
    },
    userEmail(state) {
      return state.profile.hasOwnProperty('email') ? state.profile.email : '';
    },
    userRoles(state, getters) {
      const claims = getters.authUserClaims;
      if (!claims.hasOwnProperty('van')) {
        return [];
      }
      if (claims.van.hasOwnProperty('r')) {
        return claims.van.r;
      }
      return [];
    },
    isAdmin(state, getters) {
      const roles = getters.userRoles;
      return roles.includes('ns.admin');
    },
    isPeopleAdmin(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'people.admin']);
    },
    isPeopleReception(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'people.admin', 'people.reception']);
    },
    isDeskBookingAdmin(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'desk-booking.admin']);
    },
    canEditNeighbourhoods(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'desk-booking.admin', 'desk-booking.neighbourhoods']);
    },
    canEditDeskSettings(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'desk-booking.admin', 'desk-booking.settings']);
    },
    canExportDeskBookingData(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['ns.admin', 'desk-booking.admin', 'desk-booking.data']);
    },
    canDeleteUser(state, getters) {
      const roles = getters.userRoles;
      return arrayContainsAny(roles, ['admin.super']);
    },
    isAuthenticated(state) {
      return Boolean(state.authUser);
    },
    ns(state, getters) {
      if (getters.authUserClaims.hasOwnProperty('van')) {
        return getters.authUserClaims.van.ns;
      }
      return '-';
    },
    userAndAuthClaimsMatch(state, getters, rootState) {
      return claimsMatchUser(rootState.user.user, getters.authUserClaims);
    },
    userTokenNeedsRefresh(state, getters, rootState, rootGetters) {
      if (!getters.isAuthenticated) return false;
      if (!rootGetters['user/userDocLoaded']) return true;
      return !getters.userAndAuthClaimsMatch;
    }
  },
  mutations: {
    setAuthUser(state, authUser) {
      state.authError = null;
      state.authUser = authUser;
      state.profile.displayName = authUser.displayName;
      state.profile.email = authUser.email;
    },
    updateAuthUserToken(state, token) {
      state.authUser.token = token;
    },
    updateAuthUser(state, authUser) {
      Object.assign(state.authUser, authUser);
      state.profile.displayName = authUser.displayName;
      state.profile.email = authUser.email;
    },
    clearUser(state) {
      state.authError = null;
      state.authUser = null;
      state.profile.displayName = '';
      state.profile.email = '';
    },
    setAuthError(state, error) {
      state.authError = error;
    },
    clearTokenRefreshTimeout(state) {
      clearInterval(state.refreshAuthTokenHandle);
    },
    setTokenRefreshTimeout(state, handle) {
      state.refreshAuthTokenHandle = handle;
    }
  },
  actions: {
    async bind({commit, getters, dispatch}) {
      log.debug('bind');
      commit('app/loading', 'auth', {root: true});
      // listen to changes in the auth state (i.e. login/out events)
      const _auth = await auth;
      _auth.onAuthStateChanged(async user => {
        try {
          if (!user) {
            // we are anonymous/logged out
            log.debug('authStateChanged: no user');
            commit('clearUser');
            dispatch('onAuthStateChanged', null, {root: true});
          } else {
            // the user has logged in!
            log.debug('authStateChanged: user', user);
            // only store the properties we use, this may help with Vue dev tools issues
            const authUser = userInfoOnly(user);
            authUser.token = await user.getIdTokenResult();
            log.debug('authStateChanged: token', authUser.token);
            commit('setAuthUser', authUser);
            dispatch('onAuthStateChanged', authUser, {root: true});
          }
        } catch (e) {
          log.debug('authStateChanged: error processing user', e);
          commit('setAuthError', e);
        } finally {
          commit('app/loaded', 'auth', {root: true});
        }
      }, err => {
        commit('setAuthError', err);
        log.error('onAuthStateChanged', err);
      }, () => {
        log.info('onAuthStateChanged completed');
        commit('app/loaded', 'auth', {root: true});
      });

      // refresh the user token whenever it looks like the token is out of date
      this.watch(
          () => getters.userTokenNeedsRefresh,
          needsRefresh => {
            if (needsRefresh) {
              log.debug(`userTokenNeedsRefresh`);
              commit('clearTokenRefreshTimeout');
              const handle = setInterval(() => {
                dispatch('refreshAuthToken').catch(err => log.warn('Auth token refresh', err));
              }, 2000);
              commit('setTokenRefreshTimeout', handle);
            } else {
              commit('clearTokenRefreshTimeout');
            }
          },
          {immediate: true}
      );
    },
    signIn(state, {email, password}) {
      return auth.then(auth => auth.signInWithEmailAndPassword(email, password));
    },
    signOut() {
      return auth.then(auth => auth.signOut());
    },
    signInPopup(context, provider) {
      log.debug('signInPopup');
      return auth.then(a => a.signInWithPopup(provider));
    },
    signInRedirect(context, provider) {
      log.debug('signInRedirect');
      return auth.then(a => a.signInWithRedirect(provider));
    },
    updateProfile({commit}, profile) {
      return auth.then(a => {
        return a.currentUser.updateProfile(profile)
            .then(() => commit('updateAuthUser', userInfoOnly(a.currentUser)));
      });
    },
    sendPasswordResetEmail(state, {email}) {
      return auth.then(auth => auth.sendPasswordResetEmail(email));
    },
    async refreshAuthToken({commit, getters, dispatch}) {
      log.debug(`refreshAuthToken`);
      const a = await auth;
      const currentUser = a.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdTokenResult(true);
        const oldClaims = getters.authUserClaims.van;
        const newClaims = idToken.claims.van;
        if (!isEqual(oldClaims, newClaims)) {
          log.debug(`refreshAuthToken: claims have changed`);
          commit('updateAuthUserToken', idToken);
          dispatch('onAuthStateChanged', getters.authUser, {root: true});
        }
      }
    }
  }
};

/**
 * Extract useful properties from a user to avoid potential issues storing a firebase object in vuex (can cause issues
 * with Vue dev tools.
 *
 * @param {firebase.UserInfo|null} user
 * @return {firebase.UserInfo|{}}
 */
function userInfoOnly(user) {
  if (!user) return {};
  return {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    providerId: user.providerId
  };
}
